@media print {
    .container {
        margin: 0 auto;
        max-width: 100%;

        .print {
            display: inline-block; }

        .noprint {
            display: none; }

        a {
            color: #000;
            text-decoration: none; }

        section {
            page-break-inside: avoid;
            break-inside: avoid; } } }
